import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Items from "./components/Items";
import Categories from "./components/Categories";
import ShowFullItem from "./components/ShowFullItem";

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      orders: [],
      currentItems: [],
      items: [
        {id: 1,
          title: 'Hüdropooniline seadistus koduks. 1tk.',
          img: 'line.jpg',
          desc: 'Paigaldus võimaldab perel aastaringselt pere rõõmuks kodus salateid, ürte ja maasikaid kasvatada.',
          category: 'varustus',
          price: '99.00'},  
        {id: 39,
        title: 'kress salat 1tk./50g.',
        img: 'kress.jpg',
        desc: 'Sisaldab A- ja C-vitamiini, foolhapet, karoteeni ja antioksüdante. Sellise võimsa makro- ja mikroelementide koostisega roheliste tarbimine on hea vähi ennetamine. Tänu kiudainete rohkusele ja madala kalorsusega sisaldusele on hernekõõsad tervislik lisand teie dieedile kehakaalu langetamisel.',
        category: 'mikro',
        price: '3.90'},
        {id: 2,
          title: 'herne mikrorohelise 1tk./50g.',
          img: 'herne.jpg',
          desc: 'Vesikressi mikrorohelised on rikkad vitamiinide (A, B1, B2, B3, B5, B6, B17, C, D, E, K, PP) ja mikroelementide (raud, kaltsium, fosfor, jood, kaalium), ensüümide ja tervist parandavate ainete poolest. söögiisu ja seedimine. See on joodiallikas, aitab kaasa kilpnäärme nõuetekohasele toimimisele.',
          category: 'mikro',
          price: '3.90'},
        {id: 3,
          title: 'mikrorohelise sinepp 1tk./50g.',
          img: 'gorchica.jpg',
          desc: 'Sinepi mikrorohelised kiirendavad ainevahetust – soodustavad kaalulangust ja tugevdavad veresoonte seinu. tugevdab immuunsüsteemi, suurendades kogu keha jõudu ja vastupidavust. Sinep on suurepärane antioksüdant, tõstab söögiisu, parandab seedimist.',
          category: 'mikro',
          price: '3.90'},
        {id: 4,
          title: 'mikrorohelise punane kapsa 1tk./50g.',
          img: 'kapsa_punane.jpg',
          desc: 'Punase kapsa mikrorohelised tugevdavad immuunsüsteemi, toniseerivad keha tervikuna, ergutavad seedimist, samuti vähendavad kolesteroolitaset veres, tervendades seeläbi südame-veresoonkonna süsteemi. Vähendab triglütseriidide taset maksas, vähendades ateroskleroosi riski.',
          category: 'mikro',
          price: '3.90'},
        {id: 5,
          title: 'mikrorohelise azuki 1tk./50g.',
          img: 'bobi_adzuki.jpg',
          desc: 'Azuki normaliseerib ainevahetust, omab antimikroobseid omadusi ja rahustab. Adzuki mikrorohelised on rikkad raua, kaaliumi ja aminohapete poolest. 100 g idandeid sisaldab 65 mg askorbiinhapet (50 g apelsinis). Seetõttu kasutatakse taimi gripi ja viirusliku külmetuse ennetamiseks.',
          category: 'mikro',
          price: '3.90'},
        {id: 6,
          title: 'mikrorohelise mung 1tk./50g.',
          img: 'bobi_munguba.jpg',
          desc: 'Mung toetab hormonaalset taset (mis on menopausi ajal eriti oluline) ja pärsib rinnavähi teket. Lisaks parandavad mungoad mälu ja nägemist, tugevdavad liigeseid ja luid, võitlevad allergiate ja astmaga ning mõjuvad soodsalt närvisüsteemile.',
          category: 'mikro',
          price: '3.90'},
        {id: 7,
          title: 'mikrorohelise brokkoli 1tk./50g.',
          img: 'brokkoli.jpg',
          desc: 'Tänu suurele sinepiõlide sisaldusele on see nagu kõik ristõielised rapiinid vähi, eriti rinna- ja eesnäärmevähi ennetamiseks ja raviks. Eemaldab kehast jääkaineid ja toksiine. Kõrge K-vitamiini sisaldus brokkoli raabi mikrorohelistes aitab tugevdada luid ning ennetab artriiti ja osteoporoosi. Brokkoli raab mikrorohelised sisaldavad suurel hulgal ensüüme, mis reguleerivad organismi ainevahetust ja mõjuvad soodsalt seedimisele. Brokkoli mikrorohelised tugevdavad immuunsüsteemi ja veresoonte seinu, vähendavad suhkrut ja normaliseerivad happesust. Brokkolilehtede mikrorohelised on dieettoode, mis sobib inimestele, kes hoolivad oma figuurist ja tervisest. Sisaldavad vitamiinid: A, C, E, B4, B6, B9 ja beetakaroteen parandavad naha tervist, nägemist, reproduktiivfunktsioone, vere koostist ja veresoonte tervist. Brokkoli mikrorohelist tasub süüa iga päev, rikastades seeläbi oma dieeti elusate ja kergesti seeditavate vitamiinidega, mis idandatud seemnes kümnekordistuvad.',
          category: 'mikro',
          price: '3.90'},
        {id: 8,
          title: 'mikrorohelise koriandr 1tk./50g.',
          img: 'koriandr.jpg',
          desc: 'Koriandri mikrorohelised on tervendava ja üldtugevdava toimega, aitavad organismist välja viia raskmetalle, alandada suhkru- ja kolesteroolitaset, normaliseerida vererõhku ja kogu südame-veresoonkonna talitlust. Koriandri mikrorohelised on külma looduse toode, kuid samal ajal parandavad nad söögiisu.',
          category: 'mikro',
          price: '3.90'},
        {id: 9,
          title: 'mikrorohelise kuldne linane 1tk./50g.',
          img: 'kuldne_len.jpg',
          desc: 'Lina mikrorohelised tugevdavad immuunsüsteemi ja ergutavad ainevahetust. Neid kasutatakse maohaavandite ja gastriidi, südame-veresoonkonna haiguste ennetamiseks ning rögalahtistina bronhiidi korral. Kõrvaldab allergiad ja düsbioosi, tugevdab luid, aitab artriidi ja artroosi korral.',
          category: 'mikro',
          price: '3.90'},
        {id: 10,
          title: 'mikrorohelise lutsern 1tk./50g.',
          img: 'lucerna.jpg',
          desc: 'Lutserni mikrorohelised aitavad kehal vabaneda jääkainetest ja toksiinidest ning stimuleerivad seedetrakti. Alandab kolesterooli ja veresuhkru taset, tugevdab veresooni, normaliseerib vererõhku. Leevendab lihaspingeid, kõrvaldab krambid.',
          category: 'mikro',
          price: '3.90'},
        {id: 11,
          title: 'mikrorohelise murulauk 1tk./50g.',
          img: 'murulauk.jpg',
          desc: 'Säilitab luude tugevuse, ennetab artriiti ja osteoporoosi. Parandab sapipõie, neerude ja soolte tööd. Sellel on antibakteriaalne, seenevastane, kasvajavastane toime. Tugevdab immuunsüsteemi ja organismi vastupanuvõimet ebasoodsatele keskkonnateguritele.',
          category: 'mikro',
          price: '3.90'},
        {id: 12,
          title: 'mikrorohelise päevalill 1tk./50g.',
          img: 'podsolnuh.jpg',
          desc: 'Päevalille mikrorohelised on põletikuvastase, rögalahtistava, haavu parandava toimega, neid soovitatakse kasutada täiendava vahendina osteoporoosi ja südame-veresoonkonna haiguste ennetamisel. Päevalille mikrorohelised sisaldavad: Vitamiine: A, rühm B (B3, B5, B6, B9), C, D, E, PP, karotiini, biotiini.',
          category: 'mikro',
          price: '3.90'},
        {id: 13,
          title: 'mikrorohelise nisu 1tk./50g.',
          img: 'pshenica.jpg',
          desc: 'Nisu mikrorohelised parandavad immuunsust, tugevdavad organismi kaitsevõimet, toniseerivad, annavad energiat. Kompenseerib vitamiinide ja toitainete puudust. Parandab naha ja juuste seisundit, aeglustab vananemisprotsesse.',
          category: 'mikro',
          price: '3.90'},
        {id: 14,
          title: 'mikrorohelise redis "China Rose" 1tk./50g.',
          img: 'redis_china_rose.jpg',
          desc: 'Redise mikrorohelised sisaldavad suures koguses C-vitamiini, mis aitab tugevdada organismi immuunsust ja oluliselt parandada naha seisundit. Lehed on eredalt lilla värvusega, mis saavutatakse foolhappe ja B6-vitamiini kõrge kontsentratsiooni tõttu. Redise võrsel on kroonleht kuni 1 sentimeetri läbimõõduga ja varre pikkus kuni 5 sentimeetrit. Kroonlehe maitse sarnaneb juurvilja omaga ja on vürtsika järelmaitsega, mistõttu saab seda kasutada mitte ainult kaunistuseks, vaid ka roa lisakomponendina. Sobib suurepäraselt köögiviljasalati või valge juustuga salati kaunistamiseks. Tükeldatud idusid võib lisada erinevatele kastmetele.',
          category: 'mikro',
          price: '3.90'},
        {id: 15,
          title: 'mikrorohelise redis "Daikon" 1tk./50g.',
          img: 'redis_daikon.jpg',
          desc: 'Daikoni mikrorohelised parandavad seedimist, puhastavad keha õrnalt toksiinidest ja aitavad vabaneda ülekaalust. Omab põletikuvastast toimet, aitab kiiresti toime tulla külmetushaiguste, viirus- ja bakteriaalsete infektsioonide, hingamisteede haigustega (bronhiit, trahheiit jne).',
          category: 'mikro',
          price: '3.90'},
        {id: 16,
          title: 'mikrorohelise redis "Red Vulcano" 1tk./50g.',
          img: 'redis_red_vulcano.jpg',
          desc: 'Sisaldab suures koguses C-vitamiini ning vitamiinide, mineraalide, tiamiini, riboflaviini ja eeterlike õlide kompleksi. Nad parandavad seedimist ja neil on mõõdukad kolereetilised ja dekongestantsed omadused. Redise idude söömine aitab parandada naha ja juuste seisundit ning tugevdada üleüldiselt organismi.',
          category: 'mikro',
          price: '3.90'},
        {id: 17,
          title: 'mikrorohelise rukola" 1tk./50g.',
          img: 'rukkola.jpg',
          desc: 'Rukola mikrorohelised sisaldavad flavonoide, mis ennetavad südame- ja veresoonkonnahaigusi ning normaliseerivad vererõhku. Parandab naha välimust, omab antibakteriaalset, põletikuvastast ja diureetilise toimega toimet, samuti vähendab maomahla tootmist, mis on kasulik gastriidi korral.',
          category: 'mikro',
          price: '3.90'},
        {id: 18,
          title: 'mikrorohelise peed" 1tk./50g.',
          img: 'svekla.jpg',
          desc: 'Peedi mikrorohelised tugevdavad immuunsüsteemi ning toniseerivad närvi- ja seedesüsteemi. Sellel on põletikuvastane, taastav ja detoksifitseeriv toime. Peedi mikrorohelised soodustavad kaalulangust (ainult 19 kcal 100 grammi kohta) ja rasvade lagunemist.',
          category: 'mikro',
          price: '3.90'},
        {id: 19,
          title: 'Lehtsalat "Aficion Batavia" potis. 1tk.',
          img: 'Lehtsalat.jpg',
          desc: 'Salati regulaarne tarbimine on äärmiselt kasulik seedimisele ja aitab parandada soolestiku tööd. Salat sisaldab E-, K-, A-, PP-, C-, B-rühma vitamiine, aga ka mineraalaineid: rauda, ​​mangaani, seleeni, vaske, tsinki. Toote kalorisisaldus on madal - ainult 15 kcal 100 kohta.',
          category: 'salads',
          price: '1.30'},
        {id: 20,
          title: 'Salat "Baby Leafs Colour" (mix) potis. 1tk.',
          img: 'baby.jpg',
          desc: 'Salati regulaarne tarbimine on äärmiselt kasulik seedimisele ja aitab parandada soolestiku tööd. Salat sisaldab E-, K-, A-, PP-, C-, B-rühma vitamiine, aga ka mineraalaineid: rauda, ​​mangaani, seleeni, vaske, tsinki. Toote kalorisisaldus on madal - ainult 15 kcal 100 kohta.',
          category: 'salads',
          price: '1.30'},
        {id: 21,
          title: 'Toitelahus maasikamattide täitmiseks. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 22,
          title: 'Toitelahus maasikatele seemikute perioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 23,
          title: 'Toitelahus maasikatele kasvuperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 24,
          title: 'Toitelahus maasikatele viljaperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 25,
          title: 'Toitelahus tomatide täitmiseks. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 26,
          title: 'Toitelahus tomatide seemikute perioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 27,
          title: 'Toitelahus tomatide kasvuperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 28,
          title: 'Toitelahus tomatide viljaperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 29,
          title: 'Toitelahus paprikade täitmiseks. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 30,
          title: 'Toitelahus paprikade seemikute perioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 31,
          title: 'Toitelahus paprikade kasvuperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 32,
          title: 'Toitelahus paprikade viljaperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
         {id: 33,
          title: 'Toitelahus salatide täitmiseks. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
         {id: 34,
          title: 'Toitelahus salatide seemikute perioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 35,
          title: 'Toitelahus salatide kasvuperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 36,
          title: 'Toitelahus salatide viljaperioodil. 1tk.',
          img: 'bottle.jpg',
          desc: 'Komposiitkontsentraat 100 liitri kohta. Sisaldab lahust A ja lahust B. Iga pudel lahjendatakse suhtega 1:100. Hind on komplekti kohta.',
          category: 'kulumaterjalid',
          price: '9.90'},
        {id: 37,
          title: 'Must basiilik. 1tk.',
          img: 'basilik.jpg',
          desc: 'Värsked basiilikulehed on rikkad A-, K-, C-vitamiini, raua, kaltsiumi, folaadi ja mangaani poolest. Taim sisaldab antioksüdante ja flavonoide, mis vähendavad kahjulike kemikaalide ja toksiinide mõju organismile. Nad ravivad ka külmetushaigusi ja tugevdavad immuunsüsteemi.',
          category: 'teised',
          price: '1.49'},  
        {id: 38,
          title: 'maasikas. 1kg.',
          img: 'maasikas.jpg',
          desc: 'Maasikad mõjuvad inimkehale järgmiselt: Aitab tugevdada kardiovaskulaarsüsteemi, mõjutab veresoonte seinte elastsust. Suurendab immuunsust ja aitab inimesel haigusest kiiremini taastuda. Kiirendab ainevahetust ning puhastab keha jääkainetest ja toksiinidest.',
          category: 'marjad',
          price: '13.90'},  
      ],
      showFullItem: false,
      fullItem: {}
    }
    this.state.currentItems = this.state.items
    this.addToOrder = this.addToOrder.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this)
    this.chooseCategory = this.chooseCategory.bind(this)
    this.onShowItem = this.onShowItem.bind(this)
  }
  render() {
    return (
      <div className="wrapper">
        <Header orders={this.state.orders} onDelete={this.deleteOrder}/>
        <Categories chooseCategory={this.chooseCategory}/>
        <Items onShowItem={this.onShowItem} items={this.state.currentItems} onAdd={this.addToOrder}/>
        {this.state.showFullItem && <ShowFullItem onAdd={this.addToOrder} onShowItem={this.onShowItem} item={this.state.fullItem} />}
        <Footer/>
      </div>  
    );
  }

  onShowItem(item) {
    this.setState({fullItem: item})
    this.setState({showFullItem: !this.state.showFullItem})
  }

  chooseCategory(category){
    if (category === 'all'){
      this.setState({currentItems: this.state.items})
      return
    }
    this.setState ({
      currentItems: this.state.items.filter(el => el.category === category)
    })
  }

  deleteOrder(id) {
    this.setState({ orders: this.state.orders.filter(el => el.id !== id)})
  } 

  addToOrder(item) {
    let isInArray = false
    this.state.orders.forEach(el => {
      if(el.id === item.id)
      isInArray = true
    })
    if (!isInArray)
      this.setState({ orders: [...this.state.orders, item]})
  }

  }

export default App;
