import React, { useState } from 'react';
import { FaShoppingBasket } from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import Order from './Order';
import { AiOutlineCloseCircle } from 'react-icons/ai'

const showOrders = (props) => {
    let summa = 0;
    props.orders.forEach(el => summa += Number.parseFloat(el.price));
    return(<div>
        {props.orders.map(el => (
            <Order onDelete={props.onDelete} key={el.id} item={el} />
        ))}
        <p className='summa'>Kokku: {summa.toFixed(2)}€</p>
    </div>)
}

const showNothing = () => {
    return(<div className='empty'>
        <h2>Ostukorv on tühi</h2>
    </div>)
}





export default function Header(props) {
    let[cartOpen, setCartOpen] = useState(false)
    let[cartAboutOpen, setCartAboutOpen] = useState(false)
    let[cartTelefoneOpen, setCartTelefoneOpen] = useState(false)

    const showAbout = () => {
        return(<div className='empty'>
            <AiOutlineCloseCircle className='close-icon' onClick={() => setCartAboutOpen(cartAboutOpen = !cartAboutOpen)}/>
            <h2>Info About us</h2>
        </div>)
    }
    
    const showTelefone = () => {
        return(<div className='empty'>
            <AiOutlineCloseCircle className='close-icon' onClick={() => setCartTelefoneOpen(cartTelefoneOpen = !cartTelefoneOpen)}/>
            <h2>Meie kontaktid</h2>
        </div>)
    }
    
  return (
    <header>
        <div>
            <span className='logo'>Leaf4Life OÜ</span>    
            <ul className='nav'>
                <li><IoIosContacts className='contact-icon' onClick={() => setCartAboutOpen(cartAboutOpen = !cartAboutOpen)}/></li>
                <li><BsFillTelephoneFill className='telefon-icon' onClick={() => setCartTelefoneOpen(cartTelefoneOpen = !cartTelefoneOpen)}/></li>
            </ul>
            <FaShoppingBasket onClick={() => setCartOpen(cartOpen = !cartOpen)} className={`shop-cart-button ${cartOpen && 'active'}`} />

            {cartOpen && (
                <div className='shop-cart'>
                    {props.orders.length > 0 ?
                    showOrders(props) : showNothing()}
                </div>
            )}

            {cartAboutOpen && (
                <div className='full-item'>
                     {showAbout()}
                </div>
            )}

            {cartTelefoneOpen && (
                <div className='full-item'>
                     {showTelefone()}
                </div>
            )}

        </div>
        <div className='presentation'></div>
    </header>
  )
}
